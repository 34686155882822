export const interactivegallery = function() {
	var element = $('.interactive-gallery'),
		image = element.find('.interactive-gallery-image'),
		checkmobile = function(){

		},
		calculateLast = function(){
			var counter = 0;
			image.removeClass('last');
			image.each(function(){
				var width = $(this).width();
				var parentWidth = $(this).parent().width();
				var percent = Math.round(100*width/parentWidth);
				counter += percent;
				if(counter == 100 || counter == 99)
				{
					counter = 0;
					$(this).addClass('last');
				}
			});
		},
		removeText = function(){
			$('.interactive-gallery-image-arrow').remove();
			$('.interactive-gallery-text').remove();
		},
		resize = function(){
			removeText();
			calculateLast();
		},
		openGalleryItem = function($image){
			removeText();
			var text = "<div class='interactive-gallery-text interactive-gallery-"+ $image.data('color') +"'> <span class='icon-close'></span>";
				if($image.data('detail'))
				{
					text += "<h3>" + $image.data('detail') + "</h3>";
				}
				if($image.data('name') && $image.data('title'))
				{
					text += "<p><strong>" + $image.data('name') + "</strong> - " + $image.data('title');
					if($image.data('link'))
					{
						text += "<br><a href='" + $image.data('link') + "'>" + $image.data('link-name') + "</a>"
					}
					text += "</p>";
				}
			text += "</div>";

			if($image.hasClass('last') && $image.index() !=  image.last().index())
			{
				$image.after(text);
				$image.append("<span class='interactive-gallery-image-arrow interactive-gallery-image-arrow-"+$image.data('color')+" interactive-gallery-image-arrow-up'>");
			}
			else
			{
				if ($image.nextAll('.last:first').index() == image.last().index() || $image.index() ==  image.last().index())
				{

					$image.prevAll('.last:first').after(text);
					$image.append("<span class='interactive-gallery-image-arrow interactive-gallery-image-arrow-"+$image.data('color')+" interactive-gallery-image-arrow-down'>");
				}
				else
				{
					$image.nextAll('.last:first').after(text);
					$image.append("<span class='interactive-gallery-image-arrow interactive-gallery-image-arrow-"+$image.data('color')+" interactive-gallery-image-arrow-up'>");
				}
			}
			$('.interactive-gallery-text .icon-close').bind('click', function(){
				removeText();
			});
		};

	$(window).resize(function(){
		var resizing;
		clearTimeout(resizing);
		resizing = setTimeout(resize, 500);
	});

	calculateLast();

	image.each(function(){
		var isDefaultOpen = $(this).data('defaultopen');
		if (isDefaultOpen == true){
			openGalleryItem($(this));
		}
	});

	image.on('click', function(){
		openGalleryItem($(this));
	});
}
