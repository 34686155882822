import Suru from "../app";

export const navsub = function(){
	var element = $('.navsub'),
		li = element.find('ul li'),
		a = li.find('a'),
		navsub = this,
		functions = Suru.functions,
		track = Suru.track,
		checkMobile = function(){
			return $('.navsub').css("word-wrap") == "normal" ? true : false;
		};

	$(window).resize(function(){
		var list = $('.navsub ul li');
		  checkMobile() ? functions.applyGradient(list, 'navsub-gradient') : list.removeClass('navsub-gradient').attr('style','');
	});

	if (track.clearToSend()) {
		a.on('click', function(){
			track.quicklinkClick($(this));
		});
	}
	li.on('mouseenter', function(){
		if(checkMobile() && $(this).hasClass('navsub-gradient'))
		{
			functions.expandElement($(this).find('a'), 'navsub-hover');
		}
	});
	li.on('mouseleave', function(){
		if(checkMobile() && $(this).hasClass('navsub-gradient'))
		{
			functions.unexpandElement($(this), 'navsub-hover');
		}
	});
	if(checkMobile() && element.length > 0)
	{
		functions.applyGradient(li, 'navsub-gradient');
	}
};
