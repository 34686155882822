export const dialog = function(opts){

	var	duration = opts.duration === undefined ?  10 : opts.duration,	
		trigger = $('.dialog-toggle');
	
	trigger.on('click', function(){	
		var content = $('#' + $(this).data('dialog-id'));
		content.show({
			duration : duration,
			complete : function() {
				$(document).one('click', function() {
					content.hide();
				});
			}
		});
	});
}
