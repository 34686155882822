export const slider = function () {

	$('#checkbox').change(function(){
		setInterval(function () {
			moveRight();
		}, 3000);
	});

	var slider = $(".slider");

	slider.each(function(){
		var sliderItem = $(this).find("li"),
			sliderList = $(this).find("ul"),
			sliderPrev = $(this).find('.control_prev'),
			sliderNext = $(this).find('.control_next'),
			slideCount = sliderItem.length,
			slideWidth = sliderItem.width(),
			slideHeight = sliderItem.height(),
			sliderUlWidth = slideCount * slideWidth;
		slider.css({ width: slideWidth, height: slideHeight });
		if(slideCount <= 2)
		{
			slideWidth = 0;
		}
		sliderList.css({ width: sliderUlWidth, marginLeft: - slideWidth });

		$(this).find("li:last-child").prependTo(sliderList);

		sliderNext.on('click',function(){
			sliderList.animate({
				left: - slideWidth
			}, 200, function () {
				$(this).find("li:last-child").prependTo(sliderList);
				sliderList.css('left', '');
			});
		});
		sliderPrev.on('click',function(){
			sliderList.animate({
				left: + slideWidth
			}, 200, function () {
				$(this).find("li:first-child").appendTo(sliderList);
				sliderList.css('left', '');
			});
		});
	});

}
