export const autocomplete = function(){
    var element = $('#mainnavSearch'),
        mainnav = $(".navmain"),
        delay = 300,
        minCharecters = 3,
        startevent = 'keyup',          
        viewAll = '.autocomplete-viewall-results',
        resultsTarget = '#autoCompleteResultId',
        resultsListMenu = '#autoCompleteResultsMenu',
        delayTimer = null,
        activate = function(){
            if(element.val().length >= minCharecters){
                clearTimeout(delayTimer);
                delayTimer = setTimeout(function(){
                    getResults();
                },delay);
            }
            else{
                clearResults();
            }
        },

        clearResults = function(){
            element.removeClass('autocomplete-active');
            $(resultsTarget).html('');
        },

        getResults = function(){
            var results = {};
            $.ajax({
                type: "GET",
                url: element.data("url") + "?term=" + element.val(),
                success: function(data) {
                    showResults(data);
                }
            });
        },
        showResults = function(data){
            var resultsHtml = $('<div>');      
            $.each(data, function(index, item){
                var categoryName,
                    categoryLabel;
                if(typeof item.category !== 'string'){
                    categoryName = 'noCategory';
                    categoryLabel = 'noCategory';
                }
                else{
                    categoryName = slugify(item.category);
                    categoryLabel = item.category;
                }
               
                var categoryDiv = $(resultsHtml).find('.'+categoryName);
                if(categoryDiv.length < 1){
                    categoryDiv = getResultCategoryHtml(categoryLabel, categoryName);
                }
                categoryDiv.find('ul').append(getResultItemHtml(item));
                resultsHtml.append(categoryDiv);
            });
            
            $(resultsTarget).html(resultsHtml);
            $(resultsListMenu).show();
            element.addClass('autocomplete-active');
            $('body').on('click.suruAutocomplete', function(e){
                var autocompleteForm = $('.autocomplete-form');
                if(!autocompleteForm.is(e.target) && autocompleteForm.has(e.target).length === 0){
                    hideResults();
                    $('body').unbind('click.suruAutocomplete');
                }
            });
            $("#autoCompleteResultsMenu ul li a").bind('click', function(ev){
                $("#autoCompleteResultsMenu").hide();
            });           
            
        },
        getResultCategoryHtml = function(label, slug){
            var categoryHtml = $('<div class="autocomplete-results-category" />');
            categoryHtml.addClass(slug);
            if(label !== 'noCategory'){
                categoryHtml.append('<h5>'+ label +'</h5>');
            }
            categoryHtml.append('<ul class="searchResults"></ul>');
            return categoryHtml;
        },
        getResultItemHtml = function(item){
            return '<li><a href="'+item.url+'">'+ item.label +'</a></li>';
        },
        slugify = function(text)
        {
          return text.toString().toLowerCase()
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
            .replace(/\-\-+/g, '-')         // Replace multiple - with single -
            .replace(/^-+/, '')             // Trim - from start of text
            .replace(/-+$/, '');            // Trim - from end of text
        },
        hideResults = function(){
            $(resultsListMenu).hide();
            element.removeClass('autocomplete-active');
            mainnav.removeClass('expanded')
        };

    element.on(startevent, function(e){
        activate();
    })
    .on('focus', function(){
        activate();
        mainnav.addClass('expanded')
    });
              
    if(viewAll!== null){
        $(viewAll).on('click', function(e){
            e.preventDefault();
            $(this).parent('form').submit();
        });
    }
}
