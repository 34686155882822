import Suru from "../app";

export const media = function(){
	//For Media Multiplay Nav
	var $switchItem = $('.media-multiplayer .switch a'),
		functions   = Suru.functions;

	const initMulitPlayerNav = function(){
		var containerWidth      = $('.switch').width(),
			itemCount           = $switchItem.length,
			totalWidth          = functions.getMultiplayerNavItemTotalWidth($switchItem);

		//Set height of each navigation item
		$('.media-multiplayer').each(function(){
			var $navItems = $(this).find('.switch-text');
			var largestItemHeight = 0;

			$navItems.each(function(){
				var itemHeight = $(this).height();
				if (itemHeight > largestItemHeight){
					largestItemHeight = itemHeight;
				}
			});
			$navItems.css('height',largestItemHeight);
		});

		if (!checkMobile()) {
			var $swtich         = $('.switch'),
				$switchRight    = $('.switch-nav-right');

			if ( totalWidth > containerWidth ) {
				$switchWrap.addClass('overflowed');
				$switchRight.removeClass('hidden');
			} else {
				$switchWrap.removeClass('overflowed');
				$switchRight.addClass('hidden');
			}

			if ( itemCount <= 5 && !$switchWrap.hasClass('overflowed') ) {
				var marginLeftCalc = (containerWidth - totalWidth) / 2 + 'px';
				$swtich.css('margin-left', marginLeftCalc);
			} else {
				$swtich.css('margin-left', 'auto');
			}

			$('.media-multiplayer .switch-nav').unbind('click').on('click', function(){
				var $switchContainer = $('.switch');

				if ( $(this).hasClass('switch-nav-right') ) {
					$switchContainer.animate({scrollLeft:'+=200'}, 500);
				} else {
					$switchContainer.animate({scrollLeft:'-=200'}, 500);
				}
			});


			$('.switch').on('scroll', function(e){
				var scrollPos = $('.switch').width() + $('.switch').scrollLeft();
				var containerWidth = $('.switch').width();
				var switchNavLeft = $('.overflowed .switch-nav-left');
				var switchNavRight = $('.overflowed .switch-nav-right');
				totalWidth = functions.getMultiplayerNavItemTotalWidth($switchItem);

					if ( scrollPos >= totalWidth   ){
						switchNavRight.addClass('hidden');
					} else {
						switchNavRight.removeClass('hidden');
					}

					if ( scrollPos <= containerWidth   ){
						switchNavLeft.addClass('hidden');
					} else {
						switchNavLeft.removeClass('hidden');
					}
			});
		} else {
			functions.initMultiplayerMobileThumbnails();
		}
	};

	$switchItem.on('click', function() {
		var $clickedSwitchItem = $(this);
		var active_button = $clickedSwitchItem.parent().find('a.active');
		var player = new Vimeo.Player($clickedSwitchItem.parent().parent().parent().find('.media-multiplayer-wrap iframe')[0]);

		if ($clickedSwitchItem.data('time')) {

			var time_split = $(this).data('time').split(':');
			if (time_split.length < 2) {
				return;
			}
			var mins = parseInt(time_split[0], 10);
			var secs = parseInt(time_split[1], 10);
			var time = mins*60 + secs;

			if ($clickedSwitchItem.data('id') == active_button.data('id')) {
				player.setCurrentTime(time);
			} else {
				player.loadVideo($clickedSwitchItem.data('id')).then(function(id) {
					// the video successfully loaded now lets play
					player.setCurrentTime(time);
					player.play();
				});
			}
		} else {
			player.loadVideo($clickedSwitchItem.data('id')).then(function(id) {
				// the video successfully loaded now lets play
				player.play()
			});
		}

		$clickedSwitchItem.parent().find('a').removeClass('active');
		$clickedSwitchItem.addClass('active');
	});

	var $switchWrap = $('.switch-wrap');

	var checkMobile = function(){
		return $(".switch-image").css("display") != "none" ? true : false;
	};

	$( window ).resize(function() {
		if (!checkMobile()) {
			initMulitPlayerNav();

			$('.media-multiplayer .switch a').each(function(){
				var isDisplayed = $(this).css('display');
				if (isDisplayed === 'none') {
					$(this).css('display', 'inline-block');
				}
			});
		} else {
			functions.initMultiplayerMobileThumbnails();

			if ( $switchWrap.hasClass('overflowed') ) {
				$switchWrap.removeClass('overflowed');
			}
		}
	});

	initMulitPlayerNav();
	/* END - For Media Video Multi */

	/* Media Light Box */
	var $mediaLightBox;
	var id;

	$('.media-lightbox.panel').on('click', function(){
		var autoplay = $(this).data('autoplay');

		id = $(this).data('id');

		$mediaLightBox = $('.media-lightbox.lightbox[data-id="' + id + '"]');

		if ($mediaLightBox.length > 0) {
			$('body').css('overflow', 'hidden');
			$mediaLightBox.slideDown();

			var player = functions.lightboxPlayer($mediaLightBox);
			player.play();
		}
	});

	$('.media-lightbox-popup-video .close').on('click', function(){
		var player = functions.lightboxPlayer($mediaLightBox);

		$('body').css('overflow', 'auto');
		player.pause();
		$mediaLightBox.slideUp('slow');
	});
	/* END - Media Light Box */
}
