import Suru from "../app";

export function accordion() {

	var element = $('.accordion'),
		trigger = $('.accordion>h2, .accordion>h3, .accordion>h4'),
		content = $('.accordion-content'),
		classExpand = 'accordion-expanded',
		expander = $('.expander-text'),
		collapser = $('.collapser-text');
	const functions = Suru.functions;

	functions.checkHash();

	trigger.on('click', function (e) {
		var parentNode = $(this).parent();

		if (!parentNode.hasClass('disabled')) {
			e.preventDefault();
			var track = Suru.track;

			parentNode.toggleClass(classExpand);
			if (parentNode.hasClass(classExpand)) {
				if (track.clearToSend()) {
					track.accordionOpen($(this));
				}

				var hash = parentNode.attr('id');
				functions.addHashToUrl(parentNode, hash);
			} else {
				if (track.clearToSend()) {
					track.accordionClose($(this));
				}
			}
		}
	});

	collapser.hide();

	$(".expander-text, .collapser-text").on('click', function (e) {
		e.preventDefault();

		if (!element.hasClass(classExpand)) {
			element.addClass(classExpand);
			$(expander).hide()
			$(collapser).show();
		} else {
			element.removeClass(classExpand);
			$(collapser).hide();
			$(expander).show();
		}
	});
}
