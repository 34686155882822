export const tabContent = function(){
	var $item = $('.tabbed-content li');
	$item.on('click', function(){
		var $clickedItem    = $(this),
			$nav            = $clickedItem.find('.tabbed-content-nav'),
			$copy           = $clickedItem.find('.tabbed-content-copy .wrap');

		if (!$nav.hasClass('active')){
			// Remove Previous Active items classes
			$('.tabbed-content-nav').removeClass('active');
			$('.tabbed-content-copy .wrap').addClass('closed');

			$nav.toggleClass('active');
			// Lets wait for active copy to animate out
			// Before we show the new copy
			setTimeout(function () {
				$copy.toggleClass('closed');
			}, 400);
		}
	});
}
