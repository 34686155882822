import Suru from "../../suru/js/app";

Site.track = (function(){
	return {
		contextPath: {},
		localePath: {},
		run: function(){
				initTracking();
		
			function initTracking() {
				var ceTrackInfo = Site.track.trackInfo;
				var digitalData = Suru.track.digitalData;

				ceTrackInfo({
					ajaxURL : '/ajax/user-info',
					eventName : 'data-layer-push-ajax-user',
					property : 'user'
				});

				if (!$('script[data-analytics-script="true"]').length > 0) {
					$(document).on('data-layer-push-ajax-page', Suru.track.pushData);
				}

				$(document).on('data-layer-push-ajax-user', function() {
					Site.track.setPageUUIDProp()
				});
				$(document).on('data-layer-push-uuid', function() {
					ceTrackInfo({
						ajaxURL : '/ajax/page-info/uuid/' + digitalData.page.uuid,
						eventName : 'data-layer-push-ajax-page',
						property : 'page'
					})
				});
			}
		},
		trackInfo: function(config){
			var digitalData = Suru.track.digitalData;

			if(typeof config.ajaxURL == 'string'){
				config.ajaxURL = Site.track.contextPath +  Site.track.localePath + config.ajaxURL;
				$.getJSON(config.ajaxURL,function(data) {
	        		if(typeof window.dataLayer === 'undefined'){
	        			window.dataLayer = [];
	        		}	        		
	        		switch(config.property) {
		        	    case 'user':
		        	    	digitalData.user = data;
		        	        break;
		        	    case 'page':
		        	    	digitalData.page = data;
		        	    	break;
		        	    case 'product':
		        	    	digitalData.product = data;
		        	    	break;
		        	    case 'article':
		        	    	digitalData.article = data;
		        	    	break;
		        	}
	        		if(config.eventName){
	        			window.dataLayer.push({
	        				'event': config.eventName, 
	        				digitalData: digitalData
	    				});	        			
	            		$(document).trigger(config.eventName);
	    			}
	    		
	        	}).fail(function( jqxhr, textStatus, error ) {
	        		if (jqxhr.statusText != 'Not Acceptable') {
	        			if(typeof window.dataLayer === 'undefined'){
	            			window.dataLayer = [];
	            		}
	        			$(document).trigger(config.eventName);
	        		}
	        		var err = textStatus + ", " + error;
            		console.log( "Request Failed: " + err );
	        	});
			}
		},
		setPageUUIDProp: function() {
			var digitalData = Suru.track.digitalData,
				uuid 		= null;
			
			$("[data-analytics-uuid]").each(function(){
				uuid = $(this).data("analyticsUuid");
			});
			digitalData.page = {
	    			"uuid": uuid
	    	};
	    	window.dataLayer.push({
	    		'event':'data-layer-push-uuid',
	    		digitalData: digitalData
	    	});
	    	$(document).trigger('data-layer-push-uuid');
		},
        ajaxAssetInfo: function(uuid){
        	digitalData = Suru.track.digitalData;

        	if(uuid){
        		ajaxURL= Site.track.contextPath + '/ajax/asset-info/uuid/' + uuid;
        	}else{
        		ajaxURL= Site.track.contextPath + '/ajax/asset-info/uuid/' + digitalData.page.uuid;
        	}

        	$.getJSON(ajaxURL,function( data ) {
        		if(typeof window.dataLayer === 'undefined'){
        			window.dataLayer = [];
        		}

        		digitalData.asset = data;
        		window.dataLayer.push({'event': 'data-layer-push-ajax-asset', digitalData: digitalData});
        		$(document).trigger('data-layer-push-ajax-asset');
        	}).fail(function( jqxhr, textStatus, error ) {
        		var err = textStatus + ", " + error;
        		console.log( "Request Failed: " + err );
        	});
        }
	}
}());