import Suru from "../app";

export const stickynav = function(opts){

	var	target = (opts === undefined || opts.target === undefined) ? '.accordion > h2' : opts.target,
		button = $(".stickynav-button"),
		element =  $('#stickynav'),
		siblingElement = element.siblings(),
		functions = Suru.functions,
		fixclass = 'stickynav-fixed',
		active = 'active',
		checkMobile = function()
		{
			return $(".stickynav").css("overflow-x") != "auto" ? true : false;
		},
		slideBar = function()
		{
			var left = $('.stickynav li a.active').offset().left + element.scrollLeft();
			$('.stickynav-active-bar').css('left', left).css('width', $('.stickynav li a.active').width());
		},
		determineHeader = function(accordionTop)
		{
			var scrollto = 160;
			if($(window).scrollTop() < accordionTop)
			{
				scrollto = 20;
			}
			$('html, body').animate({scrollTop: accordionTop - scrollto},{ duration: 1000 });
		};

	$(window).resize(function(){
		 if(checkMobile() && element.length>0)
		{
			functions.applyGradient($('.stickynav ul li'), 'stickynav-gradient');
			element.removeAttr('style');
			if($(window).scrollTop() >= $('.stickynav').offset().top)
			{
				element.addClass(fixclass);
				if (siblingElement.length > 0) {
					siblingElement.hide();
				}
			}
		}
		else if(!checkMobile() && element.length>0)
		{
			$('.stickynav ul li').removeClass('stickynav-gradient').attr('style','');
			var slidedown = $(".cookie-identifier.slidedown");
			if(slidedown.length > 0)
			{
				window.pageYOffset > slidedown.outerHeight() ? $('.stickynav').css("top", 100) : $('.stickynav').removeAttr("style").css("top", (slidedown[0].offsetHeight-window.pageYOffset)+100);
			}
		}
	});

	if(element.length>0)
	{
		var originalheight = $('.stickynav').offset();
		var slidedown = $(".cookie-identifier.slidedown");
		originalheight = originalheight.top;
		var heightarray = [];
		$(target).each(function(){
		if($(this).is(":visible"))
		{
			var h2text = $(this).text();
			const accordionHeader = $(this);
			heightarray.push(accordionHeader);
			$("#stickynav ul").append("<li><a>"+h2text+"</a><span class='icon-right'></span></li>");
			$("#stickynav ul li a:contains("+h2text+")").bind("click",
			function()
			{
				var accordion = $(target+":contains("+h2text+")").offset();
				if(checkMobile())
				{
					$('html, body').animate({scrollTop: accordion.top - 22},{ duration: 1000 });
				}
				else
				{
					determineHeader(accordion.top);
				}
			});
		}
		});
		var elementlist = $("#stickynav ul li a");
		$(window).scroll(function() {
			var cookieslider = slidedown.length > 0 ? slidedown.outerHeight() : 0;
			if ($(this).scrollTop() >= (originalheight + cookieslider)) {
				element.addClass(fixclass);
				if (siblingElement.length > 0) {
					siblingElement.hide();
				}
			} else {
				element.removeClass(fixclass);
				if (siblingElement.length > 0) {
					siblingElement.fadeIn();
				}
			}
			if($("footer").length>0)
			{
				var overflow = 'stickynav-overflow';
				$(this).scrollTop() > $(document).height() - $("footer").height() - element.height() - 101 ? element.addClass(overflow) : element.removeClass(overflow);
			}
			for(var i=0;i<heightarray.length;i++)
			{
				var top = $(heightarray[i]).parent().offset();
				var plusvalue = !checkMobile() ? 162 : 22 ;
				if($(this).scrollTop()+plusvalue>=top.top && $(this).scrollTop()+plusvalue<=($(heightarray[i]).parent().height() + top.top) && top.top != 0)
				{
					$(elementlist[i]).addClass(active);
					if(!checkMobile())
					{
						slideBar();
					}
				}
				else
				{
					$(elementlist[i]).removeClass(active);
				}
			}
		});
	}
	var li = element.find('ul li');
	li.on('mouseenter', function(){
		if(checkMobile() && $(this).hasClass('stickynav-gradient'))
		{
			functions.expandElement($(this).find('a'), 'stickynav-hover');
		}
	});
	li.on('mouseleave', function(){
		if(checkMobile() && $(this).hasClass('stickynav-gradient'))
		{
			functions.unexpandElement($(this), 'stickynav-hover');
		}
	});

	if(checkMobile() && element.length > 0)
	{
		functions.applyGradient(li, 'stickynav-gradient');
	}
}
