import Suru from "../app";

export const audienceSplash = function(){
	const functions   = Suru.functions;

	functions.matchHeight('.audience-content', '.audience-header');

	$(window).resize(function(){
		functions.matchHeight('.audience-content', '.audience-header');
	});


}
