export const navtabs = function(){
	
	var activeTab = "navtabs-active-bar",
		tabNavItems = $('.navtabs-title-container li'),
		tabContentItems = $('.navtabs-content'),
		hash = null;
		show = function(component, hash){
			tabContentItems.hide();
			tabNavItems.removeClass(activeTab);			
			$('.navtabs-content-container '+hash).show();
			component.addClass(activeTab);
		};	

	tabNavItems.each(function(i,e){		
		
		var contentHash = $(this).find('a').attr('href');
			
		if(window.location.hash && window.location.hash == contentHash)
		{
			show($(this), contentHash);
		}		
		else if ( $(tabNavItems[i]).hasClass(activeTab) )
		{
			show($(this), contentHash);
		}
		
		$(this).find('a').bind('click', function(){
			show($(this).parent(), contentHash);		
			return false;
		});
	})
}