import Suru from "../app";

export const nav = function() {

	var element =  $('.navmain'),
		button = $(".navmain-button"),
		mobileSearch = element.find('.navmain-mobile-buttons .search-container .nav-button'),
		desktopSearch = element.find('.navmain-searchbox input'),
		subMenu = element.find('.navmain-sub'),
		functions = Suru.functions,
		checkMobile = function(){
		 	return $(".navmain-button").css("display") == "none" ? true : false;
		},
		bindOpen = function(){
			element.find('.navmain-sub-collapsed > a').one('click', function(e)
			{
				e.preventDefault();
			});
		};

	$(window).resize(function(){
	  	if(checkMobile())
	   	{	
			element.removeClass('navmain-open');
			$('.navmain-panel-open').remove();
			subMenu.find("ul").removeAttr("style");
		}
	  	else
	  	{
	  		element.find('.navmain-sub-expanded ul').show();
	  		bindOpen();
	  	}
	});

	subMenu.click(function()
	{
		if(!checkMobile() && $(this).hasClass('navmain-sub-collapsed'))
		{
			$(this).toggleClass("navmain-sub-collapsed navmain-sub-expanded").find('ul').slideToggle();
			bindOpen();
		}					
	});

	subMenu.each(function(){
		$(this).addClass('navmain-sub-collapsed');
	});
	
	if(!checkMobile())
	{
		bindOpen();
	}

	mobileSearch.on('click', function(){
		const container = $(this).closest('.search-container');
		const navul = $('.navmain ul');
		const search = $(this).siblings('.nav-mobile-search');
		container.addClass('search-container-expanded');
		navul.addClass("navmain-greyout");
		$('.navmain-greyout a').bind('click',function(ev){
			ev.preventDefault();
			ev.stopPropagation();
		});
		search.focus();
		search.bind('blur', function(){
			container.removeClass('search-container-expanded');
			navul.removeClass('navmain-greyout');
		});
	});

   	$(document).mousedown(function(e) {
		var clickTarget = $(e.target);
		var isInSearchbox = clickTarget.parents('.navmain-searchbox').length > 0;
		if(!isInSearchbox && clickTarget.closest('#autoCompleteResultsMenu').length == 0)
		{
			$("#autoCompleteResultsMenu").hide();
			$('.navmain-searchbox').removeClass("navmain-searchbox-expanded");
		}	
    });

	desktopSearch.focus(function()
	{			
		$(this).closest('.navmain-searchbox').addClass("navmain-searchbox-expanded");
	});

	element.find('.navmain-searchbox label').on('click', function(ev)
	{
		if(desktopSearch.val() != "")
		{
			$(this).closest('form').submit();
		}	
	});	
	mobileSearch.siblings('nav-button').on('click', function()
	{
		if($('.navmain-mobile-buttons .search-container input').val() != "")
		{
			$(this).closest('form').submit();
		}
	});	

	button.on('click', function(){ functions.openPanel('navmain'); });
	$('.navmain-close').on('click', function(){ functions.closePanel('navmain'); });
}

