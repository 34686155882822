export const iframeform = function() {
    window.addEventListener(
        "message",
        function(e) {
            var $iframe = $("#iframeForm");
            var $loadingGif = $(".form-loading-gif");

            var eventName = e.data[0];
            var data = e.data[1];

            switch (eventName) {
                case "setHeight":
                    $iframe.height(data);
                    $loadingGif
                        .addClass("form-hidden-element")
                        .promise()
                        .done(function() {
                            $iframe.removeClass("form-hidden-element");
                        });
                    break;
                case "submitted":
                    $iframe
                        .addClass("form-hidden-element")
                        .promise()
                        .done(function() {
                            $loadingGif.removeClass("form-hidden-element");
                        });
                    break;
            }
        },
        false
    );
};
