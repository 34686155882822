export const basket = function() {

	var basket_total = 0;
	var basket_total_el = $('.basket .total');
	$('.literature-item .add-container').on('click', function(event) {
		var span = $(this).find('span');
		if (!span.hasClass('added')) {
			++basket_total;
		} else {
			--basket_total;
		}
		span.toggleClass('added');
		basket_total_el.html(basket_total);

		event.preventDefault();
	});

}