import Suru from "../app";

export const navbutton = function(){
    var track = Suru.track;

    if (track.clearToSend()) {
		var element = $('.nav-button');
		element.on('click', function(e){
			track.navbuttonClick($(this));
		});
	}

}
