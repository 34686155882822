export const article = function(){

	var element = $('.article'),
		articleMeta = element.find('.article-meta'),
		articleSummary = element.find('.article-summary');

	$('.article-expander').on('click', function(e){
		$(this).siblings('.article-meta').slideToggle();
		$(this).toggleClass('expanded');
		$(window).resize(function(){
			if(articleSummary.css('display') == 'block') {
				articleMeta.show();
			}
        });
	});
}
