export const forms = function(){
	
	var activeClass = "activated",		
		activeTextboxes = $('.forms-textbox input,.forms-textarea textarea'),
		activeDropdown = $('.forms-dropdown select'),
		radio = $('.forms-radio-off,.forms-radio-on'),
		checkbox = $('.forms-checkbox-off,.forms-checkbox-on'),
		toggle = $('.forms-toggle-off,.forms-toggle-on'),
		password = $('input[type="password"]'),
		
		passwordTests = {
	        //keep these ordered from hard -> easy as they are evaluated in order
	        veryStrong: /^(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])(?=\S*?[^\w\*])\S{12,}$/,
	        strong: /^(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])\S{10,}$/,
	        medium: /^(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])\S{8,}$/,
	        weak: /(?=.{6,}).*/,
	        veryWeak: /.*/
	    };
	
	/*
		Password strengths and logic		
	*/
	
	password.each(function(){
		
		// Default password
		
		var strengthIndicatorPrimary = $(this).siblings('.forms-textbox-password-primary');
        if(strengthIndicatorPrimary.length > 0){
        	$(this).on('keyup', function(){
            	passwordStrength($(this), 'primary');
            });
        }
		
		/* 
			First of Two step logic for confirmation of passwords
		
			1) Password strength
			2) Password correct/incorrect match
		*/
		
		// 1
        var strengthIndicatorSecondary = $(this).siblings('.forms-textbox-password-secondary');
        if(strengthIndicatorSecondary.length > 0){
        	$(this).on('keyup', function(){
            	passwordStrength($(this), 'secondary');
            });
        }        
		// 2   
        var strengthIndicatorConfirm = $(this).siblings('.forms-textbox-password-confirm');
        if(strengthIndicatorConfirm.length > 0){
			$(this).on('keyup', function(){
            	passwordConfirm($(this));
        	});
        }
	});
	
	var passwordConfirm = function(element){
        var myValue = element.val();
        var equalToValue = $('#'+element.data('equal-to')).val();
        if(equalToValue)
        {
            if(myValue.length > 1 && equalToValue.length > 1 && myValue === equalToValue){
                element.siblings('.forms-textbox-password-confirm').removeClass('forms-textbox-password-wrong').addClass('forms-textbox-password-confirmed');
            } else if(myValue.length > 1 && equalToValue.length > 1 && myValue !== equalToValue){
				element.siblings('.forms-textbox-password-confirm').addClass('forms-textbox-password-wrong').removeClass('forms-textbox-password-confirmed');
			} else {
				element.siblings('.forms-textbox-password-confirm').removeClass('forms-textbox-password-confirmed').removeClass('forms-textbox-password-wrong');
            }
    	}
    }
	
	var passwordStrength = function(element, whichClass) {
		var elemValue = element.val();
		
		if(elemValue.length == 0) {
			element.siblings('.forms-textbox-password-'+whichClass).removeClass().addClass('forms-textbox-password-'+whichClass);
		    return false;
		}
		
		$.each(passwordTests, function(index, value){
            if(value.test(elemValue)){
					element.siblings('.forms-textbox-password-'+whichClass).removeClass().addClass('forms-textbox-password-'+whichClass+' forms-textbox-password-'+index);
                return false;
            }
        });
    }
	
	/*
		Adds active border to Textboxes		
	*/
	
	activeTextboxes.on('keyup', function(e){
		if($(this).val().length > 0){
			$(this).addClass(activeClass);
        } else {
        	$(this).removeClass(activeClass);
        }
	});
	
	/*
		Adds active border to Dropdown		
	*/
	
	activeDropdown.on('change', function(e){
		if($(this).val() !== ''){
			$(this).addClass(activeClass);
        } else {
        	$(this).removeClass(activeClass);
        }
	});
	
	/*
		Radio enabled		
	*/

	
	radio.on('click', function(e){
		var inputs = $(this).children("input[type='radio']").prop('checked',true).change(),
        	groupname = inputs.attr('name');
			
        $("form .forms-radio-on[name="+groupname+"]").toggleClass('forms-radio-on forms-radio-off');
        $(this).toggleClass('forms-radio-off forms-radio-on');
        $("form .forms-radio-off[name="+groupname+"]").children("input[type='radio']").prop('checked',false);
	});
	
	/*
		Toggle enabled		
	*/
	

	toggle.on('click', function(e){
		$(this).children("input[type='checkbox']").prop('checked',!$(this).children('input')[0].checked).change();
            $(this).toggleClass('forms-toggle-off forms-toggle-on');
            e.preventDefault();
        }).on('click', 'label', function(e){
        	e.stopPropagation();
        	e.preventDefault();
        }).on('click', 'label a', function(e){
        	e.stopPropagation();
    });
	
	/*
		Checkbox enabled		
	*/
	
	checkbox.on('click', function(e){
		$(this).children("input[type='checkbox']").prop('checked',!$(this).children('input')[0].checked).change();
            $(this).toggleClass('forms-checkbox-off forms-checkbox-on');
            e.preventDefault();
        }).on('click', 'label', function(e){
        	e.stopPropagation();
        	e.preventDefault();
        }).on('click', 'label a', function(e){
        	e.stopPropagation();
    });
}