export const stickymenu = function(){
 	var	lastScroll = window.pageYOffset,
 		headerEl = $(".navmain-mobile-scroll"),
 		header = $(".navmain-mobile-scroll, .row-navmain"),
 		sticky = $('.stickynav');
 		headerTop = 0,
 		cookieIdentifier = $(".cookie-identifier.slidedown"),
 		checkMobile = function(){
 			return $('.navmain-mobile-scroll').css('display') == 'none' ? true : false;
 		};

 	$(window).resize(function(){
	 	if(!checkMobile())
	 	{
			if( $(".cookie-identifier.slidedown").length > 0)
			{	
				var both = $(".navmain-mobile-scroll, .row-navmain");
				window.pageYOffset > $(".cookie-identifier.slidedown").height() ? both.css("top", 0) : both.removeAttr("style").css("top", $(".cookie-identifier.slidedown")[0].offsetHeight-window.pageYOffset);	
		   	}
	 	}
 	});

 	$(window).scroll(function() {
 		if(!checkMobile())
	  	{
		 	var newScroll = window.pageYOffset;
		 	if (newScroll > lastScroll)
		 	{
			 	headerTop -= newScroll-lastScroll;
			 	var value = -100;
			 	if(sticky.length > 0 )
			 	{
			 		value = -140;
			 	}
			 	if (headerTop < value)
			 	{
			 		headerTop = value;
			 	}			 	
		 	}

		 	else
		 	{
		 		headerTop += lastScroll-newScroll;
		 		if (headerTop > 0)
		 		{
		 			headerTop = 0;
		 		}
		 	}

		 	header.css("top",headerTop+"px");

		 	sticky.css("top",(headerTop+100)+"px");	 

		 	if (cookieIdentifier.length > 0 && newScroll < cookieIdentifier[0].offsetHeight)
		 	{
		 		header.css("top", cookieIdentifier[0].offsetHeight-newScroll+"px");
		 		sticky.css("top", (cookieIdentifier[0].offsetHeight-newScroll+100)+"px");
		 	}

		 	lastScroll = newScroll;

		 	if(headerEl.hasClass('home-background'))
		 	{
			 	var point = cookieIdentifier.length > 0 ? cookieIdentifier[0].offsetHeight : 0;
			 	newScroll > point ? headerEl.addClass('active') : headerEl.removeClass('active');
		 	}

		 	if (newScroll <= 0 && cookieIdentifier.length == 0)
		 	{
		 		header.css("top", "0px");
		 		sticky.css("top", "100px");
		 	}
		}
	});
}