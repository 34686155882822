import Suru from "../app";

export const functions = {

	addHashToUrl: function(node, hash){
		if ( node.length ) {
		  node.attr( 'id', '' );
		}
		window.location.hash = hash;
		if ( node.length ) {
		  node.attr( 'id', hash );
		}
	},

	checkOverflow: function(item){
		return item[0].offsetWidth < item[0].scrollWidth ? true : false;
	},

	determineExpandWidth: function(ul){
		var x = 220;
		while(ul[0].scrollWidth > x)
		{
			if(x==780)
			{
				break;
			}
			x = x+80;
		}
		return x;
	},
	expandElement: function(element, addclass){
		if(functions.checkOverflow(element))
		{
			$(element).parent().addClass(addclass).stop().animate({width: functions.determineExpandWidth(element)},200);
		}
	},
	unexpandElement: function(element, removeclass){
		element.stop().animate({width: element.parent().width()},200,function(){$(this).removeClass(removeclass);});
	},
	applyGradient: function(element, addclass){
		element.each(function(){
			if(functions.checkOverflow($(this).find('a')))
			{
				$(this).addClass(addclass);
			}
		});
	},
	openPanel: function(element){
		$('.'+element).addClass(element+'-open');
		$('body').append('<div class="'+element+'-panel-open"></div>');
		$('.'+element+'-panel-open').bind('click', function(e){
		   functions.closePanel(element);
		   $('.'+element+'-panel-open').remove();
		});
	},
	closePanel: function(element){
		$('.'+element).removeClass(element+'-open');
		$('.'+element+'-panel-open').remove();
	},
	// For Media Multiplayer
	getMultiplayerNavItemTotalWidth: function($switchItem) {
		var totalWidth = 0;
		$switchItem.each(function(){
			totalWidth = totalWidth + $(this).outerWidth();
		});
		return totalWidth;
	},
	// For Media Multiplayer
	initMultiplayerMobileThumbnails: function() {
		var mainVimeoId = $('.media-multiplayer-wrap').data('id');
		var i = 0;
		$('.media-multiplayer .switch a').each(function(){
			if (i !== 0) {
				var thumbVimeoId = $(this).data('id');
				if(thumbVimeoId == mainVimeoId){
					$(this).hide();
				}
			}
			i++;
		});
	},
	initTabbedContentLayout: function($parent){
		$parent.each(function(){
			var $items = $(this).find('.tabbed-content-nav');
			var $currentTabbedContent = $(this);

			$items.each(function(){
				var itemNumber = $(this).data('item');
				var copyToMove = $currentTabbedContent.find('.tabbed-content-copy[data-item="' + itemNumber + '"]').clone();

				$(this).after(copyToMove)
				copyToMove.addClass('mobileContent');
				$currentTabbedContent.addClass('mobile');
			})
		});
	},
	breakPoints: function() {
		var currentScreenSize   = 'desktop',
			desktop             = 1024,
			tablet              = 965,
			mobile              = 568;

		var windowWidth = $(window).outerWidth();
		if (windowWidth > tablet && currentScreenSize != 'desktop') {
			currentScreenSize = 'desktop';
		} else if (windowWidth <= tablet && windowWidth > mobile && currentScreenSize != 'tablet') {
			currentScreenSize = 'tablet';
		} else if (windowWidth <= mobile && currentScreenSize != 'mobile') {
			currentScreenSize = 'mobile';
		}

		return currentScreenSize;
	},
	lightboxPlayer: function($mediaLightBox) {
		var iframe = $mediaLightBox.find('iframe')[0];
		var player = new Vimeo.Player(iframe);

		return player;
	},

	checkHash: function() {
		var self = this;
		var hash = window.location.hash;
		if (hash) {
			self.scrollToOnLoad(hash);
		}
	},
	/**
	 * @param   hash = Target Id to scroll to
	 *          upperOffset = Offet from hash
	 *          scrollSpeed = How fast we are scrolling to hash
	 */
	scrollToOnLoad: function (hash, upperOffset, scrollSpeed) {
		var target = $(hash);

		if (upperOffset == undefined) {
			upperOffset = 0;
		}
		if (scrollSpeed == undefined) {
			scrollSpeed = 1500;
		}
		if (target.length) {
			$('html,body').animate({
				scrollTop: target.offset().top - upperOffset
			},scrollSpeed);

			// Lets open accordion once we scroll there
			setTimeout(function () {
				if (target.hasClass('accordion')){
					target.addClass('accordion-expanded');
				}
			}, scrollSpeed + 150);
		}
	},

	/**
	 * Find largest height and set sibiling items to that height
	 * Passing in two classes will evaulate the first passed in param
	 * @param   el      = target item class
	 * @param   elTwo   = second target item class
	 */
	matchHeight: function(el, elTwo) {
		for (var i = 0; i < arguments.length; i++) {
			var $item = $(arguments[i]);
			var largestHeight = 0;

			$item.each(function(){
				$item.css('height', 'auto');
				largestHeight = Math.max(largestHeight, $(this).height());
			})
			$item.css('height', largestHeight + 'px');
		}
	}

}
