import { navsub } from "./partials/_navsub";
import { iframeform } from "./partials/_iframe-form";
import { media } from "./partials/_media";
import { tabbedContent } from "./partials/_tabbed-content";
import { functions } from "./partials/_functions";
import { article } from "./partials/_article";
import { accordion } from "./partials/_accordion";
import { audienceSplash } from "./partials/_audience-splash";
import { autocomplete } from "./partials/_autocomplete";
import { basket } from "./partials/_basket";
import { cookie } from "./partials/_cookie";
import { dialog } from "./partials/_dialog";
import { filters } from "./partials/_filters";
import { interactivegallery } from "./partials/_interactive-gallery";
import { forms } from "./partials/_forms";
import { literature } from "./partials/_literature";
import { nav } from "./partials/_nav";
import { navbutton } from "./partials/_navbutton";
import { photoquotepanel } from "./partials/_photo-quote-panel";
import { scrollAnimation } from "./partials/_scroll-animation";
import { slider } from "./partials/_slider";
import { stickymenu } from "./partials/_stickymenu";
import { stickynav } from "./partials/_stickynav";
import { tabContent } from "./partials/_tab-content";
import { navtabs } from "./partials/_tabs";
import { track } from "./partials/_track";
import { trackingerrorrangebar } from "./partials/_tracking-error-range-bar";

var Suru = {
    iframeform,
    navsub,
    media,
    functions,
    tabbedContent,
    accordion,
    article,
    audienceSplash,
    autocomplete,
    basket,
    cookie,
    dialog,
    filters,
    forms,
    interactivegallery,
    literature,
    nav,
    navbutton,
    photoquotepanel,
    scrollAnimation,
    slider,
    stickymenu,
    stickynav,
    tabContent,
    navtabs,
    track,
    trackingerrorrangebar
};

export default Suru;