import Suru from "../app";

export const tabbedContent = function(){
	var $navItem = $('.tabbed-content-nav');
	var functions = Suru.functions;
	var $parent = $('.tabbed-content');

	if ( functions.breakPoints() !== 'desktop') {
		functions.initTabbedContentLayout($parent);
	}

	$parent.each(function(){
		var $items = $(this).find('.tabbed-content-copy');
		var i = 0;
		var isMobileLayout = $(this).hasClass('mobile');

		$items.each(function(){
			if (i == 0 && !$parent.hasClass('isEditMode')){
				$(this).addClass('open').slideDown();
			} else if ($parent.hasClass('isEditMode')) {
				$(this).addClass('open').slideDown();
			}

			i++
		})
	});

	if( !$parent.hasClass('isEditMode') ) {
		$navItem.on('click', function(){
			var $clickedNavItem = $(this)
			var itemNumber = $clickedNavItem.data('item');
			var $parent = $clickedNavItem.closest('.tabbed-content');
			var $contentToShow = $parent.find('.tabbed-content-copy[data-item="' + itemNumber + '"]');
			var $prevContent = $parent.find('.tabbed-content-copy.open');
			var $initialContent = $parent.find('.tabbed-content-copy');

			$parent.find('.tabbed-content-nav').removeClass('active');
			$clickedNavItem.addClass('active');

			if ($prevContent.length > 0){
				$prevContent.slideUp(300, function(){
					setTimeout(function () {
						$contentToShow.addClass('open').slideDown();
					}, 300);
				});
			} else {
				$contentToShow.addClass('open').slideDown();
			}
		});
	}

	$(window).resize(function(){
		var $parent = $('.tabbed-content');
		var isMobileLayout = $parent.hasClass('mobile');
		var currentScreenSize = functions.breakPoints();

		if (isMobileLayout == false){
			if(functions.breakPoints() == 'tablet' || functions.breakPoints() == 'mobile'){
				functions.initTabbedContentLayout($parent);
			}
		}

		if (isMobileLayout == true && functions.breakPoints() == 'desktop'){
			$parent.each(function(){
				$(this).removeClass('mobile');
			})
			$('.mobileContent').remove();
		}
	});
}
