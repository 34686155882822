export const photoquotepanel = function(){
	var element = $('.photo-quote-panel'),
		photo = element.find('.profile-image'),
		panel = element.find('.photo-quote-panel-panel'),
		close = element.find('.photo-quote-panel-panel-close');

	photo.on('click', function(ev){
		$('.photo-quote-panel-panel.expanded').hide();
		photo.removeClass('blue');
		$(this).addClass('blue');
		var id = $(this).data("quote-id");
		var thiselement = $('.photo-quote-panel-panel#' + id);
		var arrow = thiselement.find('.photo-quote-panel-panel-arrow');
		var left = $(this).offset().left - $(this).closest('.photo-quote-panel').offset().left;
		arrow.hide();
		arrow.css('left', left+($(this).find('img').width()/2)-20);
		thiselement.show().addClass('expanded');
		arrow.show();
	});
	close.on('click', function(){
		$(this).closest('.photo-quote-panel-panel').slideUp();
	});
	photo.first().trigger('click');
}