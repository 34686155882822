
export const literature = function() {

	$('.literature-item .add-container').on('click', function(event) {
		var span = $(this).find('span');
		if (span.hasClass('icon-add')) {
			span.toggleClass('icon-add');
			span.toggleClass('icon-minus');
			span.addClass('active');
		} else {
			span.toggleClass('icon-minus');
			span.toggleClass('icon-add');
			span.removeClass('active');
		}

		event.preventDefault();
	});

	$('.basket-literature-item .add-container').on('click', function(event) {
		$(this).parent().parent().remove();
		event.preventDefault();
	});

}