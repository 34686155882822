export const cookie = function() {
    var cookieSlider = $(".cookie-identifier.slidedown"),
        cookieLightbox = $(".cookie-identifier.lightbox"),
        accept = $('#accept-cookie'),
        dismiss = $('#dismiss-cookie'),
        remove = function(){
            if (cookieSlider.length > 0) 
            {
                cookieSlider.slideUp('slow', function(){  
                    $('.cookie-identifier').remove();
                    var cur = $(window).scrollTop();
                });
                var sticky = $('.stickynav');
                if(sticky.length > 0 && sticky.css("overflow-x") == "auto")
                {
                    sticky.animate({top: 100});
                }
                $(".navmain-mobile-scroll, .row-navmain").animate({top: 0});
            } 
            else if (cookieLightbox.length > 0)
            {
                $('body').removeClass('blurme');
                cookieLightbox.remove();
            }
        },
        read = function(name){
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for(var i=0;i < ca.length;i++) {
                var c = ca[i];
                while (c.charAt(0)==' ') c = c.substring(1,c.length);
                if (c.indexOf(nameEQ) == 0)
                    return c.substring(nameEQ.length,c.length);
            }
            return null;
        },
        set = function(c_name, value, exdays) {
            var exdate = new Date();
            exdate.setDate(exdate.getDate() + exdays);
            var c_value = escape(value) + ((exdays == null) ? "" : "; expires=" + exdate.toUTCString());
            document.cookie = c_name + "=" + c_value;
        },
        get = function(c_name) {
            var i, x, y, ARRcookies = document.cookie.split(";");
            for (i = 0; i < ARRcookies.length; i++) {
                x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
                y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
                x = x.replace(/^\s+|\s+$/g, "");
                if (x == c_name) {
                    return unescape(y);
                }
            }
        },
        clear = function() {
            var cookies = document.cookie.split(";");
            for (var i = 0; i < cookies.length; i++) {
                var cookie = cookies[i];
                var eqPos = cookie.indexOf("=");
                var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
            }
        },
        getAcceptedTerms = function(){
            this.read('InvescoAcceptedTerms');
        };

    if(cookieSlider.length > 0) 
    {
        cookieSlider.slideDown({ duration: 'slow', step: function(now,fx)
        { 
            if(window.pageYOffset < cookieSlider.height()) 
            {
                var both = $(".navmain-mobile-scroll, .row-navmain");
                var sticky = $('.stickynav');
                if(sticky.length > 0 && sticky.css("overflow-x") == "auto")
                {
                    window.pageYOffset > cookieSlider.height() ? sticky.css("top", 100) : sticky.removeAttr("style").css("top", (cookieSlider[0].offsetHeight-window.pageYOffset)+100);                         
                }
                window.pageYOffset > cookieSlider.height() ? both.css("top", 0) : both.removeAttr("style").css("top", cookieSlider[0].offsetHeight-window.pageYOffset); 
            }
        }});
    }
    else if (cookieLightbox.length > 0)
    {
        $('body').addClass('blurme');
    }

    dismiss.click(function(e){
      // At this stage, a cookie will already be planted: 'InvescoCookieNotice'
      remove(); // close cookie banner
    });

    accept.click(function(e){
        e.preventDefault();
    
        var url = $(this).closest("form").attr("action");
        $.post(url, function() {
            remove();
        }).fail(function() {
            remove();
        });
    });
}