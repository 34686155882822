import Suru from "../app";

export const filters = function() {
	var doublefilter = $('.filters + .filters .filters-group'),
		first = $('.filters').first()
	if(doublefilter.length > 0)
	{
		doublefilter.find('.filters-clear-all').remove();
		var cloned = doublefilter.clone(true).addClass('filters-added-group');
		first.append(cloned);
		first.addClass('filters-added-groups');
		doublefilter.remove();
	}
	var element = $('.filters'),
		filterGroup = element.find('.filters-group ul li a'),
		filterGroupUL = element.find('.filters-group'),
		clearAll = element.find('.filters-clear-all a'),
		button = $(".filters-button"),
		functions = Suru.functions,
		track = Suru.track,
		shareClassFilter = $('.filters-share-class'),
		clearSelectAllShareClass = $('.filters-share-class-clear-select-all'),
		checkMobile = function(){
			return $(".filters-button").css("display") == "none" ? true : false;
		},
		applyGradient = function(){
			filterGroupUL.find("ul").each(function(){
				$(this).css("display","block");
				if(functions.checkOverflow($(this)))
				{
					$(this).parent().addClass('filters-group-gradient');
				}
				if($(this).parent().find("h3").hasClass("filters-collapsed"))
				{
					$(this).css("display","none");
				}
			});
		},
		loadShareClasses = function(){
			var selectedShareClass = shareClassFilter.find('.filter-selected').map(function() {
				return $(this).parent().data("share-class");
			}).get();
			var tr = $('table tbody tr');
			tr.addClass("filtered-data-hide").closest('.accordion').addClass("filtered-data-hide");
			if(selectedShareClass.length == 0)
			{
				tr.removeClass("filtered-data-hide").closest('.accordion').removeClass("filtered-data-hide");
			}
			else
			{
				$.each(selectedShareClass, function(index,value) {
					tr.filter("."+value).removeClass("filtered-data-hide").closest('.accordion').removeClass("filtered-data-hide");
				});
			   }
		};

	if(element.length > 0)
	{
		button.removeClass('filters-button-hidden');
	}

	$(window).resize(function(){
		 if(checkMobile())
		  {
			element.removeClass('filters-open');
			$('.filters-panel-open').remove();
			applyGradient();
		}
		else
		{
			filterGroupUL.removeClass('filters-group-gradient').attr('style','');
		}
	});

	filterGroup.click(function()
	{
	   $(this).toggleClass("filter-selected");
	   if (track.clearToSend()) {
		   $(this).hasClass("filter-selected") ? track.filterApply($(this)) : track.filterUnApply($(this));
	   }
	});

	clearAll.click(function()
	{
		$(this).closest('.filters').find('.filters-group:not(.filters-added-group) ul li a').removeClass('filter-selected');
	});

	element.find('.filters-group h3').not('.filters-clear-all').click(function()
	{
		$(this).hasClass('filters-collapsed') ? $(this).next().slideDown(function(){$(this).prev().toggleClass('filters-collapsed filters-expanded');}) : $(this).next().slideUp((function(){$(this).prev().toggleClass('filters-expanded filters-collapsed');}));
	});

	filterGroupUL.on('mouseenter', function(){
		if(checkMobile() && $(this).hasClass('filters-group-gradient'))
		  {
			  functions.expandElement($(this).find("ul"), 'filters-group-hover');
		}
	});
	filterGroupUL.on('mouseleave', function(){
		if(checkMobile() && $(this).hasClass('filters-group-gradient'))
		  {
			functions.unexpandElement($(this), 'filters-group-hover');
		}
	});

	if(shareClassFilter.length > 0)
	{
		if($('.filters-share-class .filter-selected').length > 0)
		{
			loadShareClasses();
		}
		shareClassFilter.find('ul li a').on('click', function(){
			loadShareClasses();
		});

		clearSelectAllShareClass.find('.filters-share-class-select-all').on('click', function(){
			shareClassFilter.find('ul li a').addClass('filter-selected');
			loadShareClasses();
		});

		clearSelectAllShareClass.find('.filters-share-class-clear-all').on('click', function(){
			shareClassFilter.find('ul li a').removeClass('filter-selected');
			loadShareClasses();
		});
	}

	if(checkMobile() && element.length > 0)
	{
		applyGradient();
	}

	button.on('click', function(){ functions.openPanel('filters'); });
	$('.filters-close').on('click', function(){ functions.closePanel('filters'); });

	var i = 0;
	filterGroupUL.each(function(){
		if (i != 0){
			var $currentGroupHeader = $(this).find('h3:not(.no-arrow)');
			var $currentGroupList = $(this).find('ul');
			var isSelected = $(this).find('.filter-selected');

			if (isSelected.length > 0 || i == 1) {
				$currentGroupHeader.removeClass('filters-collapsed').addClass('filters-expanded');
				$currentGroupList.slideDown(1000);
			} else {
				$currentGroupList.slideUp(1000);
				$currentGroupHeader.removeClass('filters-expanded').addClass('filters-collapsed');
			}
		}
		i++;
	});
}
