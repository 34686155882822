import Suru from "../../suru/js/app";

$(document).ready(function() {
	Suru.nav();
	Suru.autocomplete();
	Suru.dialog({});
	Suru.cookie();

	if ($('.accordion').length > 0) {
		Suru.accordion();
	}

	if ($('.filters').length > 0) {
		Suru.filters();
	}
	
	if ($('.stickynav').length > 0) {
		Suru.stickynav({});
	}
	
	Site.track.run();


});