export const scrollAnimation = function(opts){
	var animQueue = [],
		win = $(window),
		debounceLimit = 100,
		debouncer,
		bidirectional = opts.bidirectional === undefined ?  false : opts.bidirectional,
		runOnPageLoad = opts.runOnPageLoad === undefined ?  false : opts.runOnPageLoad,
		lastScroll = 0,
	isAnimating = function(){
		var itemsAnimating = animQueue.filter(function(item){
			return item.running;
		});
		return (itemsAnimating.length > 0 ? true : false);		
	},
	onScroll = function(){
	    var curScrollPos = win.scrollTop();
	    var itemsToAnimate = animQueue.filter(function(item){
	        return curScrollPos >= item.trigger && !item.ran || runOnPageLoad && !item.ran;
	      });
	      runOnPageLoad = false;
	      itemsToAnimate.forEach(function(animItem){
	        if(typeof animItem.waitFor !== "undefined"){
	          var waitFor = animQueue.filter(function(waitingOnTest){
	            return waitingOnTest.id == animItem.waitFor;
	          });
	          waitFor.forEach(function(waitItem){
	            if(waitItem.ran){
	              delete animItem.waitFor;
	              onScroll();
	            }
	          });
	        }
	        else if(!animItem.running){
	          animItem.running = true;
	          animItem.action(function(){
	            animItem.running = false;
	            animItem.ran = true;
	            onScroll();
	          })
	        }        
	      });
	};
	this.add = function(item){
		animQueue.push(item);
		if(runOnPageLoad){onScroll();}
	};
	win.on('scroll', function(){
	    if(!debouncer){
	        debouncer = setTimeout(function(){
	          clearTimeout(debouncer);
	          debouncer = null;
	          var currentScroll = $(this).scrollTop();
	          if(!bidirectional)
	          {
	        	  if(currentScroll > lastScroll)
	        	  {
	        		 onScroll();
	        	  }
	          }
	          else
	          {
	        	  onScroll();
	          }
	          lastScroll = currentScroll;
	        }, debounceLimit);
	      }
	});			
}