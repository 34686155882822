import Suru from "../app";

export const track = (function(){
	return {

		filterApply: function(element){
			track.event('Filter', 'Applied', element.text().trim().replace(/\(\d+\)$/,''));
		},
		filterUnApply: function(element){
			track.event('Filter', 'Unapplied', element.text().trim().replace(/\(\d+\)$/,''));
		},
		quicklinkClick: function(element){
			track.event('Quicklinks', 'Clicked', element.text());
		},
		accordionClose: function(element){
			track.event('Accordion', 'Closed', element.text());
		},
		accordionOpen: function(element){
			track.event('Accordion', 'Opened', element.text());
		},
		navbuttonClick: function(element){
			track.event('Nav Button', 'Clicked', element.text());
		},
		debug: false,
		digitalData: {},
		suppressAnalytics: {},
		clearToSend: function(){
			var $suspendAnalytics = $('[data-suspend-analytics]');
			if ( $suspendAnalytics.length > 0 ){
				if ($suspendAnalytics.data('suspendAnalytics') || window.dataLayer == undefined) {
					return false;
				}
			}
			return true;
		},
		pushData: function(data){
			if(typeof window.dataLayer === 'undefined'){
				window.dataLayer = [];
			}
			window.dataLayer.push({'event': 'data-layer-push', digitalData: track.digitalData});
		},
		event: function(category, action, label, value){
			if(typeof category === 'undefined' || typeof action === 'undefined'){
				try{
					console.error('track.event(category, action, [label], [value]); - Category and action required');
				}
				catch(e){}
			}
			else{

				console.log('Cat: ' + category + ' Action: ' + action + ' Label: ' + label);

				var digitalData = {
						event: {
							eventInfo: {
								'timeStamp': new Date()
							}
						}
				};

				$.extend(digitalData.event.eventInfo, {'eventName': 'data-layer-event'});

				if(typeof action !== 'undefined'){
					$.extend(digitalData.event.eventInfo, {'eventAction': action});
				}
				if(typeof category !== 'undefined'){
					$.extend(digitalData.event.eventInfo, {'eventType': category});
				}
				if(typeof label !== 'undefined'){
					$.extend(digitalData.event.eventInfo, {'eventLabel': label});
				}
				if(typeof value !== 'undefined'){
					$.extend(digitalData.event.eventInfo, {'eventValue': value});
				}

				try{
					if(this.debug === false){
						window.dataLayer.push({
							'event' : digitalData.event.eventInfo.eventName,
							'eventCategory' : digitalData.event.eventInfo.eventType,
							'eventAction' : digitalData.event.eventInfo.eventAction,
							'eventLabel' : digitalData.event.eventInfo.eventLabel,
							'eventValue' : digitalData.event.eventInfo.eventValue
						});
					}
					else{
						console.info('DEBUG TRACK.EVENT', settings);
					}
				}catch(e){}
			}
		}

	}
}());
