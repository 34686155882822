export const trackingerrorrangebar = function(){
	var element = $('.tracking-error-range-bar');

	element.each(function(){
		var	low = $(this).find('.data-low').data('low');
		var	current = $(this).find('.data-current').data('current');
		var	high = $(this).find('.data-high').data('high');
		var lowtohigh = $(this).find('.low-to-high');
		var currentline = $(this).find('.current-line');
		var range = high - low;
		var rangepixels = (range/100) * 80 * 10;
		var rangeleftpixels = (low/100) * 80 * 10;
		var currentleftpixels = (current/100) * 80 * 10;
		lowtohigh.css('width', rangepixels);
		lowtohigh.css('left', rangeleftpixels);
		currentline.css('left', currentleftpixels);
	});
}
